#about {
    padding-top: 7rem;
    padding-bottom: 1rem;
    background-color: $secondary-color;
    text-align: center;

    .title {
        font-size: 36px;
    }

    .subtitle {
        font-size: 20px;
    }

    .affliation {
        font-size: 12px;
    }
}

#about-p2 {
    padding-top: 7rem;
    text-align: center;

    .about-icon {
        padding: 20px 18px 20px 18px;
        margin-right: 6px;
        border-radius: 30%;
        background-color: $secondary-color;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition: all 0.1s ease-in-out;
    }

    .about-icon:hover {
        background-color: $primary-color;
        color: $white-color;
    }
}