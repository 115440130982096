#events {
    padding-top: 7rem;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .col-description {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    @media screen and (min-width: 992px) {
        .mobile{
            display: none;
        }
    }
        
    @media screen and (max-width: 991px) {
        .col-description {
            text-align: center;
        }

        .pc{
            display: none;
        }
    }
}