#calendar {
    padding-top: 6rem;
    padding-bottom: 2rem;
    text-align: center;
    
    img {
        object-fit: cover;
        width: 90%;
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        align-content: center;
    }
}